.great p {
  font-family: Roboto Flex;
  font-weight: 400px;
  color: #0f3a57;
}

.middle-text {
  margin: 50px 0 120px;
}

.signature {
  margin-top: 100px;
}

.great-mind {
  position: absolute;
  bottom: 0;
}

.middle-text {
  font-weight: 700 !important;
}

@media only screen and (max-width: 480px) {
  .great {
    padding-bottom: 0 !important;
    padding-left: 0 !important;
  }

  .great-mind-content {
    padding-left: 24px;
    padding-right: 24px;
  }

  .great-mind {
    position: relative !important;
  }

  .middle-text {
    margin: 50px 0 60px;
  }

  .signature {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .great {
    padding-bottom: 0 !important;
    padding-left: 0 !important;
  }

  .great-mind-content {
    padding-left: 24px;
    padding-right: 24px;
  }

  .great-mind {
    position: relative !important;
  }

  .middle-text {
    margin: 50px 0 60px;
  }

  .signature {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .great {
    padding-bottom: 0 !important;
    padding-left: 0 !important;
  }

  .great-mind-content {
    width: 700px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .great-mind {
    position: relative !important;
  }

  .middle-text {
    margin: 50px 0 60px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .great {
    padding-left: 140px;
  }

  .great-mind-content {
    width: 500px;
  }

  .great-mind {
    width: 980px;
    left: 495px;
  }

  .middle-text {
    margin: 50px 0 60px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1700px) {
  .great {
    padding-left: 140px;
  }

  .great-mind-content {
    width: 600px;
  }

  .great-mind {
    width: 1180px;
    left: 580px;
  }

  .middle-text {
    margin: 50px 0 60px;
  }
}

@media only screen and (min-width: 1701px) {
  .great {
    padding-left: 280px;
  }

  .great-mind-content {
    width: 780px;
  }

  .great-mind {
    width: 1180px;
    left: 940px;
  }

  .middle-text {
    margin: 50px 0 120px;
  }
}

/*# sourceMappingURL=index.d62ebe84.css.map */
